.slider-container {
  position: relative;
}

.slider {
  position: relative;
  overflow: hidden;
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.sliderImage {
  width: 100%;
}

.slider-cards {
  /* position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  width: 400px; */


  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* text-align: center; */
  z-index: 1;
  width: 1280px;
}

.slider-cards-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.white-line {
  /* width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); */


  /* Old Css */
  /* width: 1280px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16%; */

  width: 1020px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 22%;
}

.card {

  /* background-color: rgba(255, 255, 255, 0.5); */

  /* border-radius: 5px;
  padding: 50px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; */

  /* max-width: 400px; */
  /* Adjust as needed */


  background-color: rgba(0, 0, 0, .2);
  border-radius: 5px;
  /* padding: 50px; */
  padding: 55px;
  margin: 0 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* max-width: 550px; */
  max-width: 470px;
}

.card.active {
  /* background-color: #ffffff; */
  background-color: rgba(0, 0, 0, .2);
  -webkit-backdrop-filter: blur(10px) brightness(0.9);
  /* backdrop-filter: blur(10px) brightness(0.9); */
  backdrop-filter: blur(4px) brightness(.6);
}

/* .card-content {
  text-align: center;
} */


.cta-button {
  display: inline-block;
  background-color: #01509b;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
}

.cta-button:hover {
  color: #fff;
}

.card-content h1 {
  font-size: 48px;
  color: white;

}

.card-content h1:hover {
  /* color: #28a4e1; */
  text-decoration: underline;
}

.card-content p {
  font-size: 16px;
  color: white;
}


.slider-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #fff;
  /* #555; */
}

.overlay {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1; */

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 1;
  background-color: transparent;
  background-image: linear-gradient(260deg, #59525273 48%, #59525273 100%);
  /* background: linear-gradient(75deg,rgba(51,115,222,.5) 4.45%,rgba(0,19,47,.5) 97.39%); */
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

}



/* @media (max-width: 1400px) {
  .sliderImage {
    width: 100%;
    height: 880px;
  }
}

@media (max-width: 1100px) {
  .sliderImage {
    width: 100%;
    height: 100%;
  }
} */

@media (max-width: 992px) {
  .card {
    max-width: 100%;
    padding: 20px;
    left: 0% !important;
    /* Adjust as needed */
  }

  .slider-cards {
    width: 100%;
    left: 50%;
  }

  .card-content h1 {
    font-size: 20px;
  }

  .card-content p {
    font-size: 14px;
  }

  .cta-button {
    padding: 4px 7px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .white-line {
    left: 0%;
    width: 100%;
  }


}

@media (max-width:1200px) and (min-width:768px) {
  .card {
    max-width: 80%;
    padding: 20px;
    /* left: 50% !important; */
  }

  .slider-cards {
    width: 450px;
    left: 30%;
  }

  .card-content h1 {
    font-size: 28px;
  }

  .card-content p {
    font-size: 16px;
  }

  .cta-button {
    padding: 6px 10px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .white-line {
    left: 0%;
    width: 100%;
  }

}

/* @media (max-width: 767px) {
  .sliderImage {
    height: 320px;
  }
} */