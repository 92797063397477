.single-new-list {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .single-new-list .thumb {
    position: relative;
  }

  .single-new-list .single-news-details {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 20px;
    transition: left 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .single-new-list:hover .single-news-details {
    left: 0;
  }

  .single-new-list h5, .single-new-list p {
    color: #fff;
  }

  .single-new-list .blog-meta li {
    display: inline-block;
    color: #fff;
    margin-right: 4px;
  }

  .single-new-list .blog-meta li::before {
    content: '|';
    margin-right: 4px;
  }

  .single-new-list .blog-meta li:first-child::before {
    content: '';
  }

  .single-new-list .thumb img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px 5px 0 0;
  }