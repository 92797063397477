.whyChooseUsRcmDiv {
    background-color: #778F9C;
    margin-top: 120px !important;
}

.benefitRcmDiv {
    /* background-color: #778F9C; */
    margin-top: 50px;
    margin-bottom: 50px;
}


.whyChooseUsRcmSecondColumn {
    padding-left: 20px;
    color: white;
}

/* .BenefitsRcmSecondColumn {
    padding-left: 200px !important;
    padding-right: 200px !important;
} */

.BenefitsRcmSecondColumn h2 {
    font-size: 26px !important;
    margin-bottom: 20px;
    /* color: #ffffff; */
}

.whyChooseUsRcmRow {
    align-items: center;
}

.whyChooseUsRcmSecondColumn h2,
.whyChooseUsRcmSecondColumn p {
    padding-left: 30px;
    padding-right: 90px;
    color: white;
}

.benefitHeading {
    font-size: 18px;
}

.benefitLists {
    padding: 15px 0px 15px 70px !important;
}



@media (max-width: 1400px) {
    /* .whyChooseUsRcmRow {
        align-items: normal;
    } */

    .whyChooseUsRcmSecondColumn h2,
    .whyChooseUsRcmSecondColumn p {
        padding-top: 30px;
    }

    .whyChooseUsRcmSecondColumn h2 {
        font-size: 26px;
    }

    .whyChooseUsRcmSecondColumn p {
        font-size: 15px;
    }

    .rcmOuterFaqsDivSection {
        margin-top: 20px !important;
    }

    .rcmOuterFaqsDivSection {
        padding: 30px 20px 0px 20px !important;
    }

    .rcmFaqsSectionTitle {
        font-size: 20px !important;
    }

    .rcmFaqsSectionBodyContent {
        padding-bottom: 10px !important;
    }

    .rcmFaqsSectionButton {
        padding: 15px 50px !important;
    }

}

@media (max-width: 1366px) {
    .BenefitsRcmSecondColumn {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .whyChooseUsRcmSecondColumn h2,
    .whyChooseUsRcmSecondColumn p {
        padding-left: 20px;
        padding-right: 20px;
    }



}

@media (max-width: 767px) {
    .benefitRcmDiv {
        margin-top: 20px !important;
    }

    .whyChooseUsRcmDiv {
        margin-top: 0px !important;
    }

    .BenefitsRcmSecondColumn {
        padding-bottom: 50px;
    }

}