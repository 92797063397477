/* .workProcessMainDiv {
    margin-top: 90px;
    margin-bottom: 90px;
}


.workProcessImageMargin {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}



@media (max-width: 767px) {

    .workProcessMainDiv {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .workProcessMainDiv h2,
    .workProcessMainDiv p {
        text-align: center;
    }

    .workProcessImageMargin {
        display: none;
    }
} */


.virtualMedicalAssistantFirstDiv {
    background-color: #778F9C;
}

.virtualMedicalAssistantSecondDiv {
    background-color: #778F9C;
}

.virtualMedicalAssistantThirdDiv {
    background-color: #778F9C;
}

.virtualMedicalAssistantFourDiv {
    background-color: #778F9C;
}


.whyChooseUsvirtualMedicalAssistantSecondColumn {
    padding-left: 20px;
    color: white;
}

.whyChooseUsvirtualMedicalAssistantRow {
    align-items: center;
}

.whyChooseUsvirtualMedicalAssistantFirstColumn img {
    width: 100%;
    height: 100%;
}


.whyChooseUsvirtualMedicalAssistantSecondColumn h2,
.whyChooseUsvirtualMedicalAssistantSecondColumn p,
.whyChooseUsvirtualMedicalAssistantSecondColumn ul {
    padding-left: 50px;
    padding-right: 90px;
    color: white;
}

.whyChooseUsvirtualMedicalAssistantSecondColumn ul li {
    color: white !important;
}

.whyChooseUsvirtualMedicalAssistantSecondColumn ul li svg {
    color: white !important;
}


.VMAWorkProcessDiv {
    padding: 35px !important;
}

.VMAWorkProcessDiv h4 {
    font-size: 20px !important;
}

.VMAWorkProcessDivTwo {
    padding: 65px !important;
}

.VMAWorkProcessDivTwo h4 {
    font-size: 20px !important;
}

@media (max-width: 1400px) {
    /* .whyChooseUsRcmRow {
        align-items: normal;
    } */

    .whyChooseUsvirtualMedicalAssistantSecondColumn h2,
    .whyChooseUsvirtualMedicalAssistantSecondColumn p {
        padding-top: 30px;
    }

    .whyChooseUsvirtualMedicalAssistantSecondColumn h2 {
        font-size: 26px;
    }

    .whyChooseUsvirtualMedicalAssistantSecondColumn p {
        font-size: 15px;
    }

}

@media (max-width: 992px) {

    .whyChooseUsvirtualMedicalAssistantSecondColumn h2,
    .whyChooseUsvirtualMedicalAssistantSecondColumn p {
        padding-left: 20px;
        padding-right: 20px;
    }

    .whyChooseUsvirtualMedicalAssistantSecondColumn h2 {
        padding-top: 10px !important;
    }

    .whyChooseUsvirtualMedicalAssistantSecondColumn p {
        padding-bottom: 10px !important;
    }

    .virtualMedicalAssistantFirstDiv,
    .virtualMedicalAssistantSecondDiv,
    .virtualMedicalAssistantThirdDiv {
        margin-bottom: 20px;
    }

    .contactNewsLetterSectionHeading {
        font-size: 20px !important;
    }

}

@media (max-width: 767px) {

    .whyChooseUsvirtualMedicalAssistantSecondColumn h2,
    .whyChooseUsvirtualMedicalAssistantSecondColumn p {
        text-align: center;
    }
}