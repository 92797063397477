.whyChooseUsWorkCompFirstDiv {
    background-color: #778F9C;
}

.whyChooseUsWorkCompSecondDiv {
    background-color: #778F9C;
}

.whyChooseUsWorkCompThirdDiv {
    background-color: #778F9C;
}

.whyChooseUsWorkCompFourDiv {
    background-color: #778F9C;
}


.whyChooseUsWorkCompSecondColumn {
    padding-left: 20px;
    color: white;
}

.whyChooseUsWorkCompRow {
    align-items: center;
}

.whyChooseUsWorkCompSecondColumn h2,
.whyChooseUsWorkCompSecondColumn p,
.whyChooseUsWorkCompSecondColumn ul {
    padding-left: 50px;
    padding-right: 90px;
    color: white;
}

.whyChooseUsWorkCompSecondColumn ul li {
    color: white !important;
}

.whyChooseUsWorkCompSecondColumn ul li svg {
    color: white !important;
}

.thirdSectionHeading {
    text-align: center;
}

.workersCompensSixBoxes {
    text-align: center;
}

.workComfaqHeading {
    color: white !important;
}

.workComfaqButton {
    color: white !important;
}

.workComfaqParagraph {
    color: white !important;
}

@media (max-width: 1400px) {
    /* .whyChooseUsRcmRow {
        align-items: normal;
    } */

    /* .whyChooseUsWorkCompSecondColumn h2,
    .whyChooseUsWorkCompSecondColumn p {
        padding-top: 30px;
    } */

    .faqsImageTag {
        height: 100%;
    }

    .whyChooseUsWorkCompSecondColumn h2 {
        font-size: 26px;
    }

    .whyChooseUsWorkCompSecondColumn p {
        font-size: 15px;
    }

}

@media (max-width: 1100px) and (min-width: 992px) {
    .faqsOuterHeading {
        display: none;
    }
}

@media (max-width: 992px) {

    .whyChooseUsWorkCompSecondColumn h2,
    .whyChooseUsWorkCompSecondColumn p,
    .whyChooseUsWorkCompSecondColumn ul {
        padding-left: 15px;
        padding-right: 15px;
    }

    .whyChooseUsWorkCompSecondColumn ul li {
        font-size: 13px;
    }

    .thirdSectionHeading {
        font-size: 28px;
    }
}

@media (max-width: 767px) {
    .whyChooseUsWorkCompFirstDiv {
        margin-bottom: 20px !important;
    }

    .whyChooseUsWorkCompSecondColumn h2,
    .whyChooseUsWorkCompSecondColumn p,
    .whyChooseUsWorkCompSecondColumn ul {
        padding-left: 10px;
        padding-right: 10px;
    }

    .thirdSectionHeading {
        text-align: left;
    }

    .workersCompensSixBoxes {
        text-align: left;
    }

    .workComfaqHeading {
        margin-top: 0px !important;
    }

}