.newsletter-section-div {
    margin-top: 90px;
    margin-bottom: 70px;
}

.first-div-column {
    background-color: #c3d3e5;
}

.first-div {
    padding: 90px 0px 0px 80px;
}

.first-div h2 {
    color: #1c3b6a;
    font-size: 76px;
}

.first-div p {
    color: #1c3b6a;
    margin-top: 40px;
    margin-bottom: 40px;
}

.first-div button {
    border-radius: 50px;
    height: 50px;
    width: 150px;
    background-color: #1c3b6a;
    border: 1px solid #1c3b6a;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: 500ms linear;
}

.first-div button:hover {
    background-color: #2daae1;
    border: 1px solid #2daae1;
    color: #fff;
}


@media (max-width: 1500px) {
    .first-div {
        padding: 20px 0px 0px 40px;
    }

    /* .career {
        transform: rotate(270deg) translateX(16%);
    } */
}

@media (max-width: 1366px) {
    .first-div {
        padding: 40px 0px 0px 40px;
    }

    .first-div h2 {
        font-size: 65px;
    }

    /* .career {
        transform: rotate(270deg) translateX(16%);
    } */
}

@media (max-width: 1200px) {
    .career {
        transform: rotate(270deg) translateX(-8%);
    }
}

@media (max-width: 1150px) {
    .first-div h2 {
        font-size: 55px;
    }
}

@media (min-width: 850px) and (max-width: 1150px) {
    .newsLetterInnerImage {
        height: 100%;
    }
}

@media (max-width: 992px) {
    .career {
        transform: rotate(270deg) translateX(20%);
    }

    .first-div h2 {
        font-size: 35px;
    }

    .first-div {
        padding: 15px 0px 0px 15px;
    }
}


@media (max-width: 767px) {
    .first-div {
        padding: 30px;
        text-align: left;
    }

    .newsletter-section-div {
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .career {
        transform: rotate(0) translateX(0%);
    }

    .first-div h2 {
        font-size: 25px;
    }


}