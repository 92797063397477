.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
    margin: 0;
}

.not-found-content {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.not-found-heading {
    font-size: 120px;
    color: #1c3b6a;
    margin: 0;
}

.not-found-text {
    font-size: 18px;
    color: #333;
    margin: 20px 0;
}

.not-found-link {
    font-size: 16px;
    color: #ffffff;
    background-color: #1c3b6a;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.not-found-link:hover {
    color: #ffffff;
}

/* Responsive styling */
@media (max-width: 768px) {
    .not-found-heading {
        font-size: 100px;
    }
}

@media (max-width: 480px) {
    .not-found-heading {
        font-size: 80px;
    }

    .not-found-text {
        font-size: 16px;
    }

    .not-found-link {
        padding: 8px 16px;
        font-size: 14px;
    }
}