.what-we-do-section {
    padding: 50px 0;
}

.what-we-do-title {
    font-size: 46px;
    color: #1c3b6a;
}

.what-we-do-row {
    border-left: 1px solid #ddd;
}

.what-we-do-item {
    padding: 30px 0;
    border-top: 1px solid #ddd;
}

.what-we-do-item:first-of-type {
    border-top: none;
}

.item-category {
    font-size: 16px;
    color: #666;
    text-align: right;
    padding: 0px !important;
    transform: rotate(270deg) translateX(-15%);
    align-content: center;
}

.career {
    transform: rotate(270deg) translateX(-22%);
}

.item-title {
    font-size: 50px;
    color: #1c3b6a;
    padding: 0px !important;
    line-height: 1em;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 600;

}

.personalInjuriyTitle{
    justify-content: flex-start;

}

.item-description {
    font-size: 16px;
    color: #333;
    padding: 0px !important;
}

.explore-link {
    color: #1c3b6a;
    text-decoration: none;
    font-weight: bold;
}

.explore-link:hover {
    text-decoration: underline;
}



@media (max-width: 992px) {
    .item-title{
        font-size: 35px;
    }
}
@media (max-width: 767.98px) {
    .item-category {
        text-align: left;
        margin-bottom: 10px;
        transform: rotate(0) translateX(0);
        padding: 10px !important;
        display: none;
    }

    .item-title {
        font-size: 24px;
        padding: 10px !important;
        justify-content: flex-start;
    }

    .item-description {
        padding: 10px !important;
    }

    .what-we-do-title {
        font-size: 24px;
        padding: 1px;
    }

    .what-we-do-section{
        padding: 25px 0px ;
    }
}