.personalInjuryFirstDiv {
    background-color: #778F9C;
}


.personalInjurySecondDiv {
    background-color: #778F9C;
}

.personalInjuryThirdDiv {
    background-color: #778F9C;
}

.personalInjuryFourDiv {
    background-color: #778F9C;
}

.piSectionThreeCenter{
    text-align: center;
}
.piSectionInnerThreeCenter{
    text-align: center;
}

.piSectionInnerThreeCenter h4{
    font-size: 22px;
}

.personalInjurySecondColumn {
    padding-left: 20px;
    color: white;
}

.personalInjuryRow {
    align-items: center;
}

.personalInjurySecondColumn h2,
.personalInjurySecondColumn p,
.personalInjurySecondColumn ul {
    padding-left: 50px;
    padding-right: 90px;
    color: white;
}

.personalInjurySecondColumn ul li {
    color: white !important;
}

.personalInjurySecondColumn ul li svg {
    color: white !important;
}

.thirdSectionHeading {
    text-align: center;
}

.workersCompensSixBoxes {
    text-align: center;
}

.piListSvg {
    align-items: flex-start !important;
}

.piListSvg:first-child svg {
    width: 40px;
    height: 40px;
}

.piListSvg:nth-of-type(2) svg {
    width: 49px;
    height: 28px;
}

.piListSvg:nth-of-type(3) svg {
    width: 40px;
    height: 40px;
}

.faqHeading {
    color: white;
}

.faqsMainDiv button {
    color: white !important;
}

.faqsparagraphSection {
    color: white !important;
}

@media (max-width: 1400px) {
    /* .whyChooseUsRcmRow {
        align-items: normal;
    } */

    .personalInjurySecondColumn h2,
    .personalInjurySecondColumn p {
        padding-top: 30px;
    }

    .personalInjurySecondColumn h2 {
        font-size: 26px;
    }

    .personalInjurySecondColumn p {
        font-size: 15px;
    }

}

@media (max-width: 1100px) and (min-width: 992px) {
    .faqHeading {
        display: none;
    }
}

@media (max-width: 992px) {

    .personalInjurySecondColumn h2,
    .personalInjurySecondColumn p,
    .personalInjurySecondColumn ul {
        padding-left: 15px;
        padding-right: 15px;
    }

    .personalInjurySecondColumn ul li {
        font-size: 13px;
    }

    .thirdSectionHeading {
        font-size: 28px;
    }
}

@media (max-width: 767px) {
    .personalInjuryFirstDiv {
        margin-bottom: 20px !important;
    }

    .personalInjurySecondDiv {
        margin-bottom: 20px !important;
    }

    .personalInjuryThirdDiv {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .personalInjurySecondColumn h2,
    .personalInjurySecondColumn p,
    .personalInjurySecondColumn ul {
        padding-left: 10px;
        padding-right: 10px;
    }

    .personalInjurySecondColumn h2 {
        margin-top: 10px !important;
    }


    .thirdSectionHeading {
        text-align: left;
    }

    .workersCompensSixBoxes {
        text-align: left;
    }


    /* .personalInjurySecondColumn ul li:first-child svg {
        margin-left: 4px;
        margin-right: 14px;
    } */

    /* .piListSvg:first-child svg,
    .secondDivListSvg:first-child svg {
        margin-left: 0 !important;
    }

    .piListSvg:first-child svg {
        width: 60px;
    }

    .piListSvg:nth-of-type(2) svg {
        width: 70px;
    }

    .piListSvg:nth-of-type(3) svg {
        width: 60px;
    } */

    .faqsHeadingSection {
        margin-top: 0px !important;
    }

    .piSectionThreeCenter{
        text-align: left;
    }
    .piSectionInnerThreeCenter{
        text-align: left;
    }

    .piSectionThreeCenter h2{
        font-size: 22px;
    }
}